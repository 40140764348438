// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-admission-js": () => import("./../../../src/components/admission.js" /* webpackChunkName: "component---src-components-admission-js" */),
  "component---src-components-carrers-list-js": () => import("./../../../src/components/carrers-list.js" /* webpackChunkName: "component---src-components-carrers-list-js" */),
  "component---src-components-continous-training-js": () => import("./../../../src/components/continous-training.js" /* webpackChunkName: "component---src-components-continous-training-js" */),
  "component---src-components-diaries-list-js": () => import("./../../../src/components/diaries-list.js" /* webpackChunkName: "component---src-components-diaries-list-js" */),
  "component---src-components-diary-js": () => import("./../../../src/components/diary.js" /* webpackChunkName: "component---src-components-diary-js" */),
  "component---src-components-index-js": () => import("./../../../src/components/index.js" /* webpackChunkName: "component---src-components-index-js" */),
  "component---src-components-new-js": () => import("./../../../src/components/new.js" /* webpackChunkName: "component---src-components-new-js" */),
  "component---src-components-news-list-js": () => import("./../../../src/components/news-list.js" /* webpackChunkName: "component---src-components-news-list-js" */),
  "component---src-components-professionals-js": () => import("./../../../src/components/professionals.js" /* webpackChunkName: "component---src-components-professionals-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alumni-js": () => import("./../../../src/pages/alumni.js" /* webpackChunkName: "component---src-pages-alumni-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-center-js": () => import("./../../../src/pages/center.js" /* webpackChunkName: "component---src-pages-center-js" */),
  "component---src-pages-doble-titulacion-js": () => import("./../../../src/pages/doble-titulacion.js" /* webpackChunkName: "component---src-pages-doble-titulacion-js" */),
  "component---src-pages-equipo-de-liderazgo-js": () => import("./../../../src/pages/equipo-de-liderazgo.js" /* webpackChunkName: "component---src-pages-equipo-de-liderazgo-js" */),
  "component---src-pages-formacion-corporativa-js": () => import("./../../../src/pages/formacion-corporativa.js" /* webpackChunkName: "component---src-pages-formacion-corporativa-js" */),
  "component---src-pages-institucional-js": () => import("./../../../src/pages/institucional.js" /* webpackChunkName: "component---src-pages-institucional-js" */),
  "component---src-pages-internacionalizacion-js": () => import("./../../../src/pages/internacionalizacion.js" /* webpackChunkName: "component---src-pages-internacionalizacion-js" */),
  "component---src-pages-preguntas-frecuentes-js": () => import("./../../../src/pages/preguntas-frecuentes.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-js" */),
  "component---src-pages-programas-internacionales-js": () => import("./../../../src/pages/programas-internacionales.js" /* webpackChunkName: "component---src-pages-programas-internacionales-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

